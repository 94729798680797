import logo from './logo.svg';
import './App.css';
import'./contactForm';
import ContactForm from './contactForm';


function App() {
  return (
    <div className="App">
     
       <div className='Header'>
          <h1>Inno Design.</h1>
       </div>
     
      <article>
          <div className='contactForm'>
            <ContactForm/>
            
          </div>
      </article>
    </div>
  );
}

export default App;
